/* App.css */

.App {
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

body {
  margin: 0;
  padding: 0;
  background-color: #000000; /* Set background color to black */
  color: #ffffff; /* Set text color to white */
  font-family: Arial, sans-serif; /* Update font family if needed */
}

.App-link {
  color: #61dafb;
}

.side-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  
}

.search-box {
  display: flex;
  align-items: center;
  margin-right: 5%; /* Adjust margin for spacing */
  margin-left: 2.5%; /* Adjust margin to the left */
  margin-top: 20px; /* Adjust margin to match the New Posts button */
  margin-bottom: -35px;
  max-width: 90%; /* Set maximum width */
}

.search-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 3%; /* Adjust margin for spacing */
  margin-left: 3%;
  width: 100%; /* Adjust width as needed */
}

.current-searches{
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 2%;
  margin-left: 3.5%;
  max-width: 300px;
}

.current-searches-list {
 display: flex;
 flex-wrap: wrap;
 margin-top: -10%;
 margin-left: -15%;
}

.current-searches-list li {
  list-style: none;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  margin: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 34px;
  font-size: 12px; /* Adjust the font size */
  line-height: 34px; /* Center the text vertically */
  overflow: visible; /* Ensure the text remains visible */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
  font-size: 10px; /* Change the font size for the text in the slider */
  font-weight: bold;
  line-height: 35px; /* Set line height to match the switch height */
  padding-left: 20px; /* Adjust the padding to shift the text more to the right */
}

/* Apply the rainbow animation to the switch background */
.slider.checked {
  font-size: 10px; /* Change the font size for the text in the slider */
  background-image: linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red);
  background-size: 600%;
  animation: rainbow 8s linear infinite;
  padding-left: 0px;
  padding-right: 20px; /* Adjust the padding to shift the text more to the right */
}

.slider:before {
  position: absolute;
  content: '';
  font-size: 12px; /* Adjust the font size */
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(50px);
}

.new-posts-button {
  width: 30%;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 20px; /* Adjust margin for spacing */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-image: linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red);
  color: #fff;
  background-size: 600%;
  animation: rainbow 8s linear infinite;
  font-weight: bold; /* Set the button text to be bold */
}

/* Style for the scrollable box */
.scrollable-box {
  width: 100%;
  max-height: 600px; /* Increased height */
  overflow-y: auto;
}

/* Style for the scrollable box */
.scrollable-box-container {
  width: 70%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  overflow-y: auto;
}

.white-rectangle {
  width: 100%; /* Extend white rectangles to touch the right side */
  /* Other styles */
}

/* Style for individual Reddit posts */
.reddit-post {
  background-color: #fff; /* White background for each post */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding inside each post */
  margin-bottom: 10px; /* Spacing between posts */
}

/* Apply the rainbow animation to the switch background */
.slider.checked {
  background-image: linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red);
  animation: rainbow 8s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
