/* RedditPosts.css */

.reddit-post {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  .post-title {
    font-size: 20px;
    margin-bottom: 4px;
    text-align: left;
  }
  
  .post-author {
    font-size: 14px;
    font-style: italic;
    color: #888888;
    margin: 0;
    margin-bottom: 2px; /* Adjust the bottom margin */
  }
  
  .post-subreddit {
    font-size: 16px;
    color: #555555;
    margin: 0;
  }
  
  .post-content {
    font-size: 16px;
    margin-bottom: 8px;
    word-wrap: break-word;
    text-align: left;
  }
  
  .post-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .post-timestamp {
    font-size: 12px;
    color: #aaaaaa;
  }
  