/* ScrollableBox.css */

.scrollable-box-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align posts to the bottom of the container */
  margin-left: auto; /* Push the container to the right */
  padding-right: 20px; /* Adjust padding for spacing */
}
