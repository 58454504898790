/* Header.css */

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #000000; /* Set background color to black */
    color: #fff;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-container img {
    width: 100px; /* Increase the width to make the logo bigger */
    height: auto; /* Maintain aspect ratio */
    margin-right: -10px;
  }

  .logo-text {
    font-size: 24px;
    margin-left: 0px; /* Adjust the margin as needed to position it properly */
    color: #fff; /* Set the color */
    font-weight: bold; /* Make the text bold */
  }

  .navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; /* Align items vertically */
  }
  
  .navigation li {
    margin-right: 20px;
    display: flex; /* Ensure all items are flex items */
    align-items: center; /* Align items vertically */
  }
  
  .navigation a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .navigation a:hover {
    color: #f1f1f1;
  }
  
  .signup-btn,
  .signin-btn {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }

  .signup-btn:hover,
  .signin-btn:hover {
  background-color: #fff;
  color: #000;
  }